import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MediaContext } from "../../../store/context/media-context";
import Slider from "react-slick";
import { SLIDER_CONFIG, POSTS } from "./index.content";
import ChromeStore from "./ChromeStore";
import TrustWidget from "./TrustWitget";
import Post from "./Post";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

const RatingOverview = ({ isTrustWidget }) => {
  const [isRendered, setIsRendered] = useState(false);
  const { isMobile } = useContext(MediaContext);
  useEffect(() => {
    setIsRendered(true);
    return () => {
      setIsRendered(false);
    };
  }, []);
  return (
    <section className="PgEM-RO">
      <div className="PgEM-RO-content">
        {isTrustWidget ? <TrustWidget /> : <ChromeStore />}
        <div className="PgEM-RO-row">
          {isRendered &&
            (isMobile ? (
              <Slider {...SLIDER_CONFIG}>
                {POSTS.map((data, i) => (
                  <Post key={i} {...data} i={i} />
                ))}
              </Slider>
            ) : (
              POSTS.map((data, i) => <Post key={i} {...data} i={i} />)
            ))}
        </div>
      </div>
    </section>
  );
};
RatingOverview.propTypes = {
  posts: PropTypes.array,
  customClass: PropTypes.string,
  excellentText: PropTypes.string,
};

export default RatingOverview;
